<template>
  <div class="w">
    <div class="crumbs">
      <router-link to="/">首页</router-link>
      <span>/</span>
      行业体系
      <span>/</span>
      <span style="color: #07C160">领导专区</span>
    </div>
    <section class="container">
      <div class="list">
        <div class="list-name">
          领导专区
        </div>
        <div class="list-box-item" v-for="item in list" :key="item.id" :class="{'active':item.id === activeID}"
             @click="checkUser(item)">
          <div class="list-box-item-left">
            <div class="list-box-item-left-name" :class="{'active':item.id === activeID}">{{ item.name }}</div>
            <div class="list-box-item-left-role" :class="{'active':item.id === activeID}">{{ item.role }}</div>
          </div>
          <div :class="{'active':item.id === activeID}" class="list-box-item-right"></div>
        </div>
        <!--        <div class="list-box">-->
        <!--          <div style="cursor: pointer" v-for="item in list" :key="item.id" :class="{'active':item.id === activeID}" @click="checkUser(item)">-->
        <!--            <div style="font-size: 16px;line-height: 25px ">{{item.name}}</div>-->
        <!--            <div style="line-height: 25px">{{item.role}}</div>-->
        <!--            <div style="border-bottom: 1px dashed #ccc"></div>-->
        <!--          </div>-->
        <!--        </div>-->

      </div>
      <div class="content">
        <div class="self">
          <div class="left">
            <img :src="userInfo.headPortrait">
          </div>
          <div class="right">
            <div class="leaderInfo-name">{{ userInfo.name }}</div>
            <div class="leaderInfo-role">{{ userInfo.role }}</div>
            <div class="workCategory">工作范畴</div>
            <div>{{ userInfo.work }}</div>
            <div></div>
          </div>
        </div>
        <div style="margin-top: 55px;font-size: 20px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 28px;">
          领导介绍
        </div>
        <div style="margin-top: 10px">{{ userInfo.introduce }}</div>
      </div>

    </section>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted} from 'vue'
import {leaderList} from '../api'
import {useRoute, onBeforeRouteUpdate} from 'vue-router'

export default defineComponent({
  name: 'Industry',
  setup() {
    const list = ref([])
    const activeID = ref(0)
    const userInfo = ref({})
    const route = useRoute()

    const getleaderList = () => {
      leaderList({pageSize: 1000, id: route.query.id ? route.query.id : ''}).then(res => {
        if (res.success) {
          list.value = res.result.records
          activeID.value = res.result.records[0].id
          userInfo.value = res.result.records[0]
        }
      })
    }
    const checkUser = (row) => {
      activeID.value = row.id
      userInfo.value = row
    }

    const info = ref({})
    const show = ref({
      one: true,
      two: false,
      three: false
    })

    const changeShow = (num) => {
      show.value[num] = !show.value[num]
    }
    onMounted(() => {
      getleaderList()
      for (const key in show.value) {
        show.value[key] = false
      }
      show.value[route.query.type || 'one'] = true
    })
    onBeforeRouteUpdate(to => {
      if (route.query.id){
        route.query.id=''
        setTimeout(() => {
          getleaderList()
        })
      }
    })

    return {list, info, show, changeShow, activeID, userInfo, checkUser}
  }
})
</script>

<style lang="scss" scoped>
.active {
  background-color: #07C160;
  color: #FFFFFF !important;
  border-color: #FFFFFF;
}

.content {
  padding: 0px 40px 60px;
}

.content {
  .self {
    display: flex;

    .left {
      margin-right: 20px;
      width: 250px;

      img {
        width: 100%;
        height: 332px;
      }
    }

    .right {
      margin-left: 10px;

      .leaderInfo-name {
        height: 45px;
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 45px;
      }

      .leaderInfo-role {
        text-align: center;
        margin-top: 18px;
        padding: 0 15px;
        height: 32px;
        border-radius: 20px;
        border: 1px solid #00BB3D;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #00BB3D;
        line-height: 32px;
      }

      .workCategory {
        margin-top: 50px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        height: 28px;
        line-height: 28px;
      }
    }
  }
}

.detail-body {
  font-size: 16px;
  line-height: 20px;
}

.title {
  text-align: center;
  font-size: 22px;
}

.info {
  line-height: 38px;
  text-align: center;
  color: #999999;
  border-bottom: 1px dotted #999999;
  margin-bottom: 60px;

  &-source {
    margin-right: 20px;
  }
}

.container {
  margin-top: 20px;
  margin-bottom: 90px;
  display: flex;
}

.content {
  flex: 1;
}

.list {
  width: 246px;
  border: 1px solid #DDDDDD;
  margin-right: 18px;
  height: 100%;

  .list-box-item {
    width: 100%;
    height: 86px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .list-box-item-left-name {
      height: 40px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 40px;
    }

    .list-box-item-left-role {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
    }

    .list-box-item-right:after {
      content: "";
      width: 1rem;
      height: 1rem;
      display: inline-block;
      border-top: 2px solid darkgray;
      border-right: 2px solid darkgray;
      transform: rotate(45deg);
    }
  }

  &-box {
    height: 400px;
    overflow: auto;
    padding: 20px 15px;
  }

  ul {
    margin-bottom: 20px;
  }

  li {
    cursor: pointer;
    height: 47px;
    line-height: 47px;
    border-bottom: 1px dotted #DDDDDD;
    width: 210px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;

    &.active {
      color: #07C160;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &-title {
    border-left: 5px solid #07C160;
    font-size: 18px;
    padding-left: 6px;
    color: #333333;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;

    i {
      display: block;
      width: 13px;
      height: 8px;
      background: url(../assets/arrow.png) no-repeat;
      background-size: 13px 8px;

      &.show {
        transform: rotate(180deg);
      }
    }
  }

  &-name {
    height: 64px;
    line-height: 64px;
    text-align: center;
    color: #ffffff;
    background: #00BB3D;
    font-size: 22px;
    font-weight: 700;
  }
}
</style>
