<template>
  <div class="w">
    <div class="crumbs">
      <router-link to="/">首页</router-link>
      <span>/</span>
      新闻通告
      <span>/</span>
      <router-link :to="{path: 'NewsList', query: {type:route.query.type}}">
        {{ route.query.title }}
      </router-link>
      <span>/</span>
      <span style="color: #00BB3D">正文</span>
    </div>
    <div class="content">
      <Detail :info="info"></Detail>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref, watch} from 'vue'
import Detail from '../components/Detail'
import {getAction} from '../api'
import {useRoute} from 'vue-router'

export default defineComponent({
  name: 'NewsDetail',
  components: {
    Detail
  },
  setup() {
    const info = ref({})
    const route = useRoute()
    const url = ref('')
    onMounted(() => {
      if (route.query.type == 1) {
        url.value = '/yncy/notice/yncyNotice/queryById'
      } else if (route.query.type == 2) {
        url.value = '/yncy/news/yncyNews/queryById'
      } else if (route.query.type == 3) {
        url.value = '/yncy/industrynews/yncyIndustryNews/queryById'
      }
      getAction(url.value, {id: route.query.id}).then(res => {
        info.value = {
          ...res.result,
          name: res.result.subject
        }
      })
    }),
      watch(
        info,
        (newValue, oldalue) => {
          console.log(newValue,oldalue)
          document.title =newValue.name
        },
        { immediate: true }
      );
    return {info, route}
  }
})
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 70px;
}
</style>
